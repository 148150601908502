(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/with-meta-tags/assets/with-meta-tags.js') >= 0) return;  svs.modules.push('/components/lb-utils/with-meta-tags/assets/with-meta-tags.js');
'use strict';

const {
  useEffect
} = React;
const {
  getDisplayName
} = svs.components.lbUtils.sportProducts;
const metaDescSelectors = ['meta[name=description]', "meta[property='og:description']", "meta[name='twitter:description']"];
const setMetaContent = metaDescription => selector => {
  var _document;
  const el = (_document = document) === null || _document === void 0 ? void 0 : _document.querySelector(selector);
  if (!el) {
    return;
  }
  el.content = metaDescription;
};
const withMetaTags = _ref => {
  let {
    Component,
    route,
    productId,
    setDocTitle = false,
    setMetaDesc = false
  } = _ref;
  return props => {
    if (!Component || !productId) {
      throw new Error('Component and productId are required');
    }
    useEffect(() => {
      const header = document.getElementById('top-content');
      const h1Image = header.querySelectorAll('.brand-brand');
      if (h1Image.length) {
        h1Image.forEach(productImage => {
          if (productImage) {
            productImage.setAttribute('alt', (route === null || route === void 0 ? void 0 : route.logotypeAltText) || getDisplayName(productId));
          }
        });
      }
      if (setDocTitle) {
        document.title = (route === null || route === void 0 ? void 0 : route.metaTitle) || "".concat(getDisplayName(productId), " - ").concat(route.label);
      }
      if (setMetaDesc) {
        metaDescSelectors.forEach(setMetaContent((route === null || route === void 0 ? void 0 : route.metaDescription) || "".concat(getDisplayName(productId), " - ").concat(route.label)));
      }
    }, []);
    return React.createElement(Component, props);
  };
};
setGlobal('svs.components.lbUtils.withMetaTags.withMetaTags', withMetaTags);

 })(window);